import React, { useState, useEffect, useMemo } from "react"
import { Routes, Route, Navigate, Link, useLocation, useNavigate } from 'react-router-dom'
// import Header from './components/Header'
import Hero from './components/Hero'
// import Select, { components } from "react-select";
import SelectAll from "./components/SelectAll";
import TechdocsFilter from "./components/TechdocsFilter"
import TechdocsFilterRadio from "./components/TechDocsFilterRadio"
import TechDocsFilterI18n from "./components/TechDocsFilterI18n"
// import OrthoSelectFacet from "./components/OrthoSelectFacet"
import { Documents, DocumentResult } from "./components/Documents"
import TechDocsError from "./components/TechDocsError"
// import DocumentClassifications from "./components/DocumentClassifications"
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import ClearFilters from "./components/ClearFilters"
import ResultsPerPageFilter from "./components/ResultsPerPageFilter"
// import Footer from "./components/Footer"
import { ModalProvider } from './components/ResultModal';
import { Button } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'
import { MdLogin, MdLogout } from 'react-icons/md'
import { FaEdit } from "react-icons/fa"
import AppRoot from './components/AppRoot'
import JSZip from 'jszip'
import JSZipUtils from './components/jszip-utils'
// import 'antd/dist/antd.css';
// import 'antd/dist/antd.min.css';
import { saveAs } from 'save-as';
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  Results,
  PagingInfo,
  Paging,
  Sorting,
  WithSearch,
  
} from "@elastic/react-search-ui";
// import {
//   BooleanFacet
// } from "@elastic/react-search-ui-views";
//import { Layout } from "@elastic/react-search-ui-views";
import './assets/css/bootstrap.min.css';
import './assets/css/aos.min.css';
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import './style.css';
//import OrthoDropDownFacet from "./components/OrthoDropDownFacet"
import classNames from 'classnames'
import { getConfig } from "./config/config-helper";
import Cookies from 'js-cookie'
// import OrthoCookie from "./components/Cookie"
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';

import useCookie from "react-use-cookie"
// import Cookie from "./components/Cookie"
// import styles from './assets/css/Modal.module.css'
import searchStyles from './assets/css/SearchResult.Module.css'
import Loader from "./components/Loader"
import ModalDropdown from './components/ModalDropdown';
import SearchInput from "./components/SearchInput"
import CopyFilter from "./components/CopyFilter";
import FaqLink from "./components/FaqLink";

function App() {

  const { redirect_uri_local, redirect_uri_env, client_id, oauth_userInfo_url, oauth_url, login_url, logout_url, country_url, lang_url, altDisplay_url,
    public_url, auth_url, search_key, engineName, auth_download_url, download_url, i18nLanguages, countriesJSON, languagesJSON, sortFields,
    resultFields, facets, autocompleteQuery, disjunctiveFacets, group, trackUrlState, debug,  Cookie_expiry_time,  page_size_options, resultsPerPagE, endpointBaseClick, employeeTypes /*,TRACKING_ID, precision*/ } = getConfig();

    var {searchFields} =getConfig();
  //ReactGA.initialize({ trackingId: TRACKING_ID });
  const currenti18nLanguageCode = Cookies.get('i18next') || 'en-us'
  const currenti18nLanguage = i18nLanguages.find((l) => l.code === currenti18nLanguageCode)
  const currenti18nLanguageName = i18nLanguages.find((l) => l.code === currenti18nLanguageCode).name;
  document.documentElement.lang = currenti18nLanguageCode;
  const { t } = useTranslation()
  let redirect_uri = window.location.hostname === 'localhost' ? redirect_uri_local : redirect_uri_env;
  const envs = ["dev","qa","prod"];
  //if (window.location.hostname === 'localhost' && process.env.NODE_ENV === 'production') {
    if (window.location.hostname === 'localhost' && envs.includes(process.env.REACT_APP_ENV.toLowerCase())) {
      redirect_uri = "http://localhost:8080/"
      // console.log("redirect_uri ", redirect_uri)
  }
  
  const [userToken, setUserToken] = useCookie('token', true);
  const [isClearFilter, setIsClearFilter] = useState(false)
  const [showCollapse, setShowCollapse] = useState(Cookies.get('showCollapse')&&(Cookies.get('showCollapse')==='false'||Cookies.get('showCollapse')===false) ? false : true);
  const [showToggle, setShowToggle] = useState(Cookies.get('showToggle')&&(Cookies.get('showToggle')==='false'||Cookies.get('showToggle')===false) ? false : true);
  // const [filterValue, setfilterValue] = useCookie('docTypeKey', '');
  const [filterValue, setfilterValue] = useState(Cookies.get("docTypeKey") ? Cookies.get("docTypeKey") : '');

  const [resultsPerPageSize, setResultsPerPageSize] = useState(Cookies.get('resultsPerPageSize') ? parseInt(Cookies.get('resultsPerPageSize')) : resultsPerPagE);
  const [totalResultCount, setTotalResultCount] = useCookie('totalResultCount', -1);
  // console.log("resultsPerPageSize",!!Cookies.get('resultsPerPageSize'),resultsPerPageSize, typeof(resultsPerPagE));

  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');
  const [fullText,setFullText] = useState(false);
  const [expirySearch, setExpirySearch] = useState(false);

  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [redirectSpinner, setRedirectSpinner] = useState(false);

  const {cssScriptKey, headerFooterDomain, searchDomain, headerApiUrl, footerApiUrl, jsHeaderFooterScriptKey, jsLibraryScriptKey} = getConfig();

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()-1).padStart(2, '0');
  const expirationFilter = {
    from: `${year}-${month}-${day}T00:00:00.000Z`,
    to: "9999-12-31T23:59:59.000Z"
  }
  
  const scriptLoaded = ()=> {   
    window.init();
    // window.setSelectedCountry();
  }

  const UrlFilterParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const location = useLocation(); 

  useEffect(() => {

    // if (window.location.href.indexOf("global") > -1) {
    //   if(window.location.hostname==='localhost')
    //     window.location.assign(redirect_uri_local+"tdhome")
    //   else
    //     window.location.assign(redirect_uri_env+"tdhome")
    //     window.reload();
    // }
    // const getScriptTag = async()=>{
    //   const [csslink] = await getCssLink();
    //   const csslinkList = csslink?.split('//')[1].split('/');
    //   csslinkList?.shift()
    //   const cssLinkTag = document.createElement("link");
    //   cssLinkTag.setAttribute("rel", "stylesheet")
    //   cssLinkTag.setAttribute("type", "text/css")
    //   cssLinkTag.href = headerFooterDomain + csslinkList?.join('/');
    //   // document.head.appendChild(cssLinkTag);
    //   document.head.insertBefore(cssLinkTag,document.head.firstChild )
    //   fetchFooterText();
    //   const [jsFullSrc] = await getHeaderText();
    //   // cssFullSrcList.shift();
    //   // const cssLink = document.createElement("link");
    //   // cssLink.async = true;
    //   // cssLink.setAttribute("rel", "stylesheet");
    //   // cssLink.setAttribute("type", "text/css");
    //   // cssLink.href = 'https://extqa.ocdx.com/' + cssFullSrcList.join('/');

    //   // console.log('css',cssLink.href);

    //   // document.head.appendChild(cssLink);

      


    //   const jsFullSrcList = jsFullSrc?.split('//')[1].split('/');
    //   jsFullSrcList?.shift()

    //   // console.log("jsFullSrcList",jsFullSrcList)

    //   const jsScript = document.createElement("script");
    //   jsScript.src = headerFooterDomain + jsFullSrcList?.join('/');

    //   // console.log('chiev',jsScript.src)

    //   document.body.appendChild(jsScript);
    //   jsScript.onload = () =>scriptLoaded();
      
    //   // setHeader(headerHtml);
    // }

    //if(process.env.REACT_APP_InitialLanguage.split(',').includes(currenti18nLanguageCode)){
      // getScriptTag();
    //}
    
    if(UrlFilterParams?.get('fullText') === "true"){
      handleFullText(true);
    }

    // console.log("******UseEffect begin")
    if (Cookies.get('search-term')) {
      // console.log('there it is',Cookies.get('search-term'))
    }
    Cookies.set("product", '', { expires: new Date(new Date().getTime() + Cookie_expiry_time) })

    document.body.dir = currenti18nLanguage.dir || 'ltr'
    document.title = t('app_title')
    var doPoll = true;
    const getAcessToken = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      //console.log("urlParams ", urlParams)
      if ((urlParams !== undefined && urlParams !== '') || Cookies.get("rt") !== undefined) {
        const code = urlParams.get('code')
        //console.log('code', code)
        //console.log('at ', Cookies.get("at"))
        if ((code !== undefined && code !== '') || Cookies.get("rt") !== undefined) {
          const getAccessToken = async () => {
            // Cookies.get("Techdocs-Country") != null ? setUserToken(false) : setUserToken(true)
            //const accessTokenFromServer = (Cookies.get("at") != undefined && Cookies.get("at") != "placeHolder") ? Cookies.get("at") : await fetchAccessToken(code)
            const accessTokenFromServer = Cookies.get("rt") === undefined ? await fetchAccessToken(code) : await fetchAWSTokenFromRT();
            // console.log("accessTokenFromServer ", accessTokenFromServer)
            if (accessTokenFromServer !== undefined && accessTokenFromServer !== '') {
              const userInfoFromServer = await fetchUserInfo(accessTokenFromServer)
              if (Cookies.get("ul") == null) {
                trackLogin(userInfoFromServer)
                const date = new Date();
                date.setDate(date.getDate() + 1);
                Cookies.set("ul", "user-tracking", { expires: date })
              }
              //console.log('userInfoFromServer', userInfoFromServer.given_name)
              setUserInfo(userInfoFromServer)
              setShowLogin(false)
              setIsDistributor(isDistributorFn(userInfoFromServer))
              var isEmployed = isEmployee(userInfoFromServer)
              var countries = [] = countriesJSON;
              var languages = [] = languagesJSON;
              if (isEmployed ) {
                if (countries.find(({ value }) => value !== 'ALL')){
                countries.unshift({ count: 0, value: 'ALL', selected: false })
                  setCountries(countries)
                }
                if (languages.find(({ value }) => value !== 'ALL')){
                  languages.unshift({ count: 0, value: 'ALL', selected: false })
                    setLanguages(languages)
                  }
              }
              else
                if (isEmployed && countries != null && countries.length === 0 && languages != null && languages.length === 0) {
                  // console.log("setting countries for employee ")
                 await fetchCountries(isEmployed)
                 await fetchLanguages(isEmployed)
                }

              const connector = new AppSearchAPIConnector({
                searchKey: `${accessTokenFromServer}`,
                engineName,
                endpointBase: auth_url,
                //searchKey: search_key,//uncomment if connecting to elastic directly
                //endpointBase: 'https://sandbox-02933b.ent.us-east-1.aws.found.io',//uncomment if connecting to elastic directly
                beforeSearchCall: (existingSearchOptions, next) => {
                  return next({
                   ...existingSearchOptions,
                    analytics: {
                      tags: ["Initial_call_no_search"]
                    }
                  })
                }
              },[]);
              if (Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null) {
                setConfig(getSecureConfig(connector, getAudienceType(userInfoFromServer), isDistributorFn(userInfoFromServer)))
              }
              else
                setShow(true)
              //setConfig({ ...config, apiConnector: secureConnector, userLogged: true })
              setUserLoggedIn(true)
              setAccessToken(accessTokenFromServer)
              if (doPoll) {
                doPollForATValidation();
                doPoll = false;
              }
            }
          }
          getAccessToken()
        }
      }
      if (!userLoggedIn) {
        getConditionalConfig()
      }
    }
    const getLanguages = async () => {
      setLoading(true)
      await fetchLanguages(isEmployee(userInfo))
      setLoading(false)
      //console.log('languages from server ', languagesFromServer)
    }
    const getCountries = async () => {
      setLoading(true)
      var countriesFromServer = await fetchCountries(isEmployee(userInfo))
      setLoading(false)
      let cntries = []
      let cookieCountry = Cookies.get("Techdocs-Country");
      if (countriesFromServer !== undefined) {
        countriesFromServer = countriesFromServer.filter(c => c.OTD_Country !== null).map((c, index) => {
          let name = c.OTD_Country
          if (c.OTD_Country !== 'ALL' || isEmployee(userInfo)) {
            //console.log("*****All selecetd ", c.OTD_Country === 'ALL')
            let selected = cookieCountry === name
            if (selected)
              // console.log("country selected is " + selected + " and is " + name + " cookie value " + cookieCountry)
            cntries[index] = { "count": 0, "value": name, "selected": selected }
          }
          return c;
        })
      }
      else {
        //console.log("countriesFromServer empty ", isEmployee(userInfo))
        cntries = countriesJSON;
        cntries = cntries.filter(c => (c.value !== 'ALL' || isEmployee(userInfo))).map(c => {
          c.selected = c.value === cookieCountry
          return c;
        })
      }
      cntries = JSON.parse(JSON.stringify(cntries))
      setCountries(cntries)
    }

    const getAltDispValues = async () => {
      let altDispValuesFromServer = await fetchAltDispValues()
      let altMap = {}
      let translatedValue = ''
      if (altDispValuesFromServer) {
        altDispValuesFromServer.map(alt => {
          //console.log(alt.ADV_TKLSourceValue + ' = ' + alt.ADV_AlternateValue);
          //console.log('alt translated value is ',t(`${alt.ADV_AlternateValue}`))
          translatedValue = t(alt.ADV_AlternateValue) ? t(alt.ADV_AlternateValue) : alt.ADV_AlternateValue
          //console.log("translated value is " + translatedValue)
          altMap[alt.ADV_TKLSourceValue] = translatedValue;
          return alt;
        })
        //console.log("altDispValues ", altMap)
        setAltDispValues(altMap)
      }
    }
    tidCheckwithParams()
    getAcessToken()
    getLanguages()
    getCountries()
    getAltDispValues()
    readCookie()
    if (Cookies.get("rt") && doPoll) {
      doPollForATValidation();
      doPoll = false;
    }
  }, [currenti18nLanguage, t, auth_url, countriesJSON, languagesJSON, engineName, search_key])

  const getFiltersFromUrl = () => {
    const currentUrl = new URL(window.location.href);
    const urlParams = new URLSearchParams(currentUrl?.search);
    const queryParams =  urlParams?.get('bm');
    return queryParams ? JSON.parse(queryParams) : []
  }

  const getUniqueArray = (array1, array2, uniqueIdentifier, authType) => {
    const currentUrl = new URL(window.location.href);
    const urlParams = new URLSearchParams(currentUrl?.search);
    const authParam =  urlParams?.get('isauthenticated');

    const uniqueFilters = [...new Map([...array2, ...array1]?.map(item => [item[uniqueIdentifier], item])).values()]
    if(authType === 'internal'){
      return uniqueFilters
    }else if(authType === 'public'){
      if(authParam === 'internal'){
        return array1
      }else if(authParam === 'public'){
        return uniqueFilters
      }else{
        return array1
      }
    }
    
  }

  const queryString = new URLSearchParams(window.location.search).get("q");

  // const [showModal, setShowModal] = useState(false)
  const [tokenExpiryTime, setTokenExpiryTime] = useState(3600)
  const [showCookieDisclaimer, setShowCookieDisclaimer] = useState(false)
  const [countries, setCountries] = useState([])
  const [countrySelected, setCountrySelected] = useState(Cookies.get("Techdocs-Country") ? Cookies.get("Techdocs-Country") : '')
  const [languages, setLanguages] = useState([])
  const [languageSelected, setLanguageSelected] = useState(Cookies.get("Techdocs-Language") ? Cookies.get("Techdocs-Language") : '')
  const [docClass, setDocClass] = useState([])
  const [showLogin, setShowLogin] = useState(true)
  // const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [altDispValues, setAltDispValues] = useState([])
  const [access_Token, setAccessToken] = useState('')
  const [userInfo, setUserInfo] = useState([])
  const [show, setShow] = useState(false)
  const [resultsSelected, setResultsSelected] = useState([])
  const [load, setLoad] = useState(false)
  const [isDistributor, setIsDistributor] = useState(false)
  const [showLoginClicked, setShowLoginClicked] = useState(false)
  const [srchTrm,setSrchTrm] = useState((new URLSearchParams(window.location.search)).get("search-term")?Cookies.get("search-term") ? Cookies.get("search-term") : '':'' || (new URLSearchParams(window.location.search)).get("docId")?Cookies.get("docId") ? Cookies.get("docId") : '':'' || (queryString ? queryString : ''))
  //const [srchTrm,setSrchTrm] = useState(Cookies.get("search-term") ? Cookies.get("search-term") : '')
  const [showHeaderLoader, setShowHeaderLoader] = useState(true);
  const [showFooterLoader, setShowFooterLoader] = useState(true);

  const setLoading = (value) => {
    setLoad(value)
  }

  function extractIndexFromString(str) {
    const regex = /filters\[(\d+)\]\[field]=audience/;
    const matches = str.match(regex);

    if (matches && matches.length === 2) {
      return parseInt(matches[1], 10);
    }
  
    return null;
  }

  function extractAudienceFilterFromURL2(url) {
    const indexNum = extractIndexFromString(decodeURIComponent(url));
    return getValuesFromURL(url, indexNum)
  }


  function extractLangCountryIndexFromString(str) {
    const regex = /filters\[(\d+)\]\[field]=language/;
    const ctryRegex = /filters\[(\d+)\]\[field]=regioncountrylist/;
    const matches = str.match(regex);
    const ctryMatches = str.match(ctryRegex);
    const indexes = []

    if (matches && matches.length === 2) {
      indexes.push(parseInt(matches[1], 10));
    }

    if (ctryMatches && ctryMatches.length === 2) {
      indexes.push(parseInt(ctryMatches[1], 10));
    }
  
    return indexes;
  }

  const getValuesFromURL = (url, indexNum) => {
    const params = new URLSearchParams(url);
    const valueList = []
    let valuesToConsider = false

    if(params.get(`filters[${indexNum}][values][0]`)){
      valuesToConsider = true
    }
    for(let i=0; i<3; i++){
      const reqValue = params.get(valuesToConsider ? `filters[${indexNum}][values][${i}]` : `filters[${indexNum}][values][0][${i}]`)
      valueList.push(reqValue)
    }
    return valueList.map(value => decodeURIComponent(value));
  }

  function extractLangCountryFilterFromURL(url) {
    const [langIndex, countryIndex] = extractLangCountryIndexFromString(decodeURIComponent(url));
    return {
      language: getValuesFromURL(url, langIndex)[0],
      country: getValuesFromURL(url, countryIndex)[0]
    }
    
    
  }

  // this useeffect contains the logic of redirection to login page base on url if user not logged in
  useEffect(()=>{

    const redirectURL = Cookies.get("redirectURL");
    const at = Cookies.get("at");
    const code = UrlFilterParams.get("code");


    const audienceValues = extractAudienceFilterFromURL2(location.search)

    if(redirectURL){
      setRedirectSpinner(true)
    }else{
      setRedirectSpinner(true);
      setTimeout(()=>{
        setRedirectSpinner(false)
      },1000)
    }

    // this check if the user has not logged in and if the url contains isauthenticated internal and the redirect cookie 
    // if the condition matches it redirects user to login page 
    // if(!at && ['internal', 'authenticated'].includes(UrlFilterParams.get('isauthenticated')?.toLowerCase()) && (!redirectURL || redirectURL === '') ){
    if(!at && (audienceValues?.includes('Internal') || audienceValues?.includes('Authenticated') || ['internal', 'authenticated'].includes(UrlFilterParams.get('isauthenticated')?.toLowerCase())) && (!redirectURL || redirectURL === '') ){
      Cookies.set("count",0, {expires: new Date(new Date().getTime() + 5 * 60 * 1000)});

      Cookies.set("redirectURL",window.location.href, {expires: new Date(new Date().getTime() + 5 * 60 * 1000)});
      Cookies.set("placeHolder", "placeHolder", { expires: 0.0007 });

      window.location.assign(login_url + redirect_uri);

    // }else if(!code && redirectURL !== "" && (Cookies.get("count")*1) < 1){
    }else if(code && window.location.href?.includes("search-result") && redirectURL !== "" && (Cookies.get("count")*1) < 1){
      // this condition checks wheteher the user logged in and the redirect cookie has value 

      // the count cookie allows to not get in to continuous loop of redirection 
      Cookies.set("count",( Cookies.get("count")*1)+1, {expires: new Date(new Date().getTime() + 5 * 60 * 1000)});
      Cookies.remove("redirectURL");
      window.location.assign(redirectURL);

    }

  },[userLoggedIn])

  useEffect(()=>{
    if(location?.search && !window.location.href.includes("search-result")){
      navigate(`tdhome/search-result/${location?.search}`);
    }
  },[])

  useEffect(() => {

    console.log("Aem header")
    

    const getScriptTag = async () => {

        const cssLinkArray = await getCssLink();

        for(let i=0; i< cssLinkArray?.length; i++){
            const csslinkList = cssLinkArray[i]?.split('//')[1].split('/');
            csslinkList && csslinkList.shift()


            const cssLinkTag = document.createElement("link");
            cssLinkTag.setAttribute("rel", "stylesheet")
            cssLinkTag.setAttribute("type", "text/css")
            cssLinkTag.href = headerFooterDomain + csslinkList?.join('/');


            document.head.insertBefore(cssLinkTag, document.head.firstChild)
        }
        

        const jsFullSrcArray = await getHeader();

        for(let i=0; i< jsFullSrcArray?.length; i++){
            const jsFullSrcList = jsFullSrcArray[i]?.split('//')[1].split('/');
            jsFullSrcList && jsFullSrcList.shift()

            const jsScript = document.createElement("script");
            jsScript.src = headerFooterDomain + jsFullSrcList?.join('/');

            setTimeout(()=>{
              document.body.appendChild(jsScript);
            },2000)
            // document.body.appendChild(jsScript);
        }

        const bootstrapScript = document.createElement("script");
        bootstrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.min.js"
        bootstrapScript.crossorigin = "anonymous"
        bootstrapScript.integrity = "sha384-QJHtvGhmr9XOIpI6YVutG+2QOK9T+ZnN4kzFN1RtK3zEFEIsxhlmWl5/YESvpZ13"

        
        // setTimeout(()=>{
        //   document.body.appendChild(bootstrapScript);
        // },2000)
        document.body.appendChild(bootstrapScript);

        // const separateJsScript = document.createElement("script");
        // separateJsScript.src = "https://dev.quidelortho.com/etc.clientlibs/quidelortho/clientlibs/clientlib-libraries.lc-bebb6b2b6d355343d40bcf3a249b79f5-lc.min.js"
       
        // setTimeout(()=>{
        //   document.head.appendChild(separateJsScript);
        // },[2000])
        


        // const separateJsScript = document.createElement("script");
        // separateJsScript.src = "/test.js"
        // separateJsScript.onload = ()=>{
        //   const btn = document.getElementsByClassName("navbar-toggler")
        //   console.log("btn", btn)
        //   btn[0].addEventListener("onclick", ()=>{
        //     console.log("click done")
        //   })
        // }

        // setTimeout(()=>{
          // document.body.appendChild(separateJsScript);
        // },2000)

        getFooter()
        

    }

    getScriptTag()
}, []);

const getCssLink = async () => {
  var tsTime = new Date(new Date().getTime());

  let cssTagHref = await fetch(headerApiUrl + `${currenti18nLanguageCode}&ts=` + tsTime)
      .then(response => response.text())
      .then(data => {
          const enc1 = data;
          const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');

          let staticLinks = []
          let links = newDocument.getElementsByTagName("link");
          for (let i = 0; i < links.length; i++) {
              if (links[i].href.indexOf(jsHeaderFooterScriptKey) !== -1 || links[i].href.indexOf(jsLibraryScriptKey) !== -1) {
                  staticLinks.push(links[i].href)
              }
          }

          return staticLinks

      }).catch((error) => {
          console.log("error occurred while fetching link");
          return ''
      });
  return cssTagHref
}


const getHeader = async () => {

  Cookies.set('IsheaderLoad',true,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });

  var tsTime = new Date(new Date().getTime());
  setShowHeaderLoader(true);

  let jsTagSrc = await fetch(headerApiUrl + `${currenti18nLanguageCode}&ts=` + tsTime)
      .then(response => response.text())
      .then(data => {
          const enc1 = data;
          const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');

          var imgs = newDocument.getElementsByTagName("img");

          for (let i = 0; i < imgs.length; i++) {
              if ((imgs[i].src.indexOf("http://localhost") !== -1) || (imgs[i].src.indexOf(searchDomain) !== -1)) {
                  let oldHeaderLogoSRC = imgs[i].src.split('//')[1].split('/');
                  oldHeaderLogoSRC.shift();
                  imgs[i].src = headerFooterDomain + oldHeaderLogoSRC.join('/');
                  if(imgs[i].srcset) imgs[i].srcset = "";
              }
          }

          const converted = newDocument.body.innerHTML;

          setHeader(converted);

          let staticLinks = []


          let scripts = newDocument.getElementsByTagName("script");

          for (let i = 0; i < scripts.length; i++) {
              if (scripts[i].src.indexOf(jsHeaderFooterScriptKey) !== -1) {
              // if (scripts[i].src.indexOf(jsLibraryScriptKey) !== -1 || scripts[i].src.indexOf(jsHeaderFooterScriptKey) !== -1 ) {
                  staticLinks.push(scripts[i].src)
              }
          }
          setShowHeaderLoader(false);
          Cookies.set('IsheaderLoad',false,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
          return staticLinks

      }).catch((error) => {
          console.log("error occurred while fetching header");
          setShowHeaderLoader(false);
          Cookies.set('IsheaderLoad',false,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
          return ''
      });
  return jsTagSrc
}

const getFooter = async () => {

  setShowFooterLoader(true);
  var tsTime = new Date(new Date().getTime());
  fetch(footerApiUrl + `${currenti18nLanguageCode}&ts=` + tsTime)
      .then(response => response.text())
      .then(data => {
          const enc2 = data;
          const newDocument2 = (new DOMParser()).parseFromString(enc2, 'text/html');

          var aTags = newDocument2.body.querySelectorAll('.footer-hyperlink-section a');

          // console.log("atags", aTags);

          for (let i = 0; i < aTags.length; i++) {
              if(aTags[i].href && (aTags[i].href.indexOf("http://localhost") !== -1) || (aTags[i].href.indexOf(searchDomain) !== -1)){
                  let oldFooterLogoHref = aTags[i].href?.split('//')[1].split('/');
                  oldFooterLogoHref && oldFooterLogoHref.shift();
                  aTags[i].href = headerFooterDomain + oldFooterLogoHref?.join('/');
              }
          }

          var imgs = newDocument2.getElementsByTagName("img");

          for (let i = 0; i < imgs.length; i++) {
              if((imgs[i].src.indexOf("http://localhost") !== -1) || (imgs[i].src.indexOf(searchDomain) !== -1)){
                  let oldFooterLogoSRC = imgs[i].src.split('//')[1].split('/');
                  oldFooterLogoSRC.shift();
                  imgs[i].src = headerFooterDomain + oldFooterLogoSRC.join('/');
              }
          }

          var sources = newDocument2.getElementsByTagName("source");

          for (let i = 0; i < sources.length; i++) {
              if((sources[i].srcset.indexOf("http://localhost") !== -1) || (sources[i].srcset.indexOf(searchDomain) !== -1)){
                  sources[i].srcset = headerFooterDomain + sources[i].srcset;
              }
          }




          const converted2 = newDocument2.body.innerHTML;
          setFooter(converted2);
          setShowFooterLoader(false);

      }).catch((error) => {
          console.log("error occurred while fetching footer");
          setShowFooterLoader(false);
          return ''
      })

}

  // let homeView = useState();

  // const addDomainToSRC = (targetDOM, query) => {
  //   const headerLogo = targetDOM.body.querySelector(query);
  //   let oldHeaderLogoSRC = headerLogo.src.split('//')[1].split('/');
  //   oldHeaderLogoSRC.shift();
  //   headerLogo.src = headerFooterDomain + oldHeaderLogoSRC.join('/');
  // }

 const handleFullText = (value) => {
  // console.log('FullText value is ',value)
  setFullText(value)
  if(value&&!searchFields.hasOwnProperty('pdfcontent'))
  searchFields.pdfcontent={};
    if(!value && searchFields.hasOwnProperty('pdfcontent') )
    delete searchFields.pdfcontent;
 }
  // const getCssLink = async () => {
  //   var tsTime = new Date(new Date().getTime());
    
  //   let cssTagHref = await fetch(getConfig().headerApiUrl + currenti18nLanguageCode + '&ts=' + tsTime)
  //     .then(response => response.text())
  //     .then(data => {
  //       const enc1 = data;
  //       const newDocument = (new DOMParser()).parseFromString(enc1, 'text/html');


  //       let staticLinks = []
  //       let links = newDocument.getElementsByTagName("link");
  //       // console.log("link",links)
  //       for(let i=0; i<links.length; i++){
  //         if(links[i].href.indexOf(process.env.REACT_APP_JsCssScriptKey) !== -1){
  //           staticLinks.push(links[i].href)
  //         }
  //       }

  //       return staticLinks
   
  //     }).catch((error)=>{
  //       // console.log("error occurred while fetching link");
  //       return ''
  //     });
  //     return cssTagHref
  // }

  // const getHeaderText = async () => {
  //   setShowHeaderLoader(true);
  //   Cookies.set('IsheaderLoad',true,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
  //   var tsTime = new Date(new Date().getTime());
  //   let jsTagSrc = await fetch(getConfig().headerApiUrl + currenti18nLanguageCode + '&ts=' + tsTime)
  //     .then(response => response.text())
  //     .then(data => {
  //       const enc1 = data;
  //       const newDocument = (new DOMParser).parseFromString(enc1, 'text/html');


  //       var imgs = newDocument.getElementsByTagName("img");

  //       for(let i=0; i< imgs.length; i++){
  //         // if(imgs[i].src.indexOf("http://") == 0 || imgs[i].src.indexOf("https://") == 0){
  //         if(imgs[i].src.indexOf(".svg") !== -1){
  //           let oldHeaderLogoSRC = imgs[i].src.split('//')[1].split('/');
  //           oldHeaderLogoSRC.shift();
  //           imgs[i].src = headerFooterDomain + oldHeaderLogoSRC.join('/');
  //         }
  //       }

  //       var sources = newDocument.getElementsByTagName("source");

  //       for(let i=0; i< sources.length; i++){
  //         if(sources[i].srcset.indexOf(".svg") !== -1){
  //           sources[i].srcset = headerFooterDomain + sources[i].srcset;
  //         }
  //       }
  

  //       const converted = newDocument.body.innerHTML;
  //       setHeader(converted);

  //       let staticLinks = []
  //       let scripts = newDocument.getElementsByTagName("script");
  //       for(let i=0; i<scripts.length; i++){
  //         if(scripts[i].src.indexOf(process.env.REACT_APP_JsCssScriptKey) !== -1){
  //           staticLinks.push(scripts[i].src)
  //         }
  //       }
  //       setShowHeaderLoader(false);
  //       Cookies.set('IsheaderLoad',false,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
  //       return staticLinks
  //     }).catch((error)=>{
  //       setShowHeaderLoader(false);
  //       Cookies.set('IsheaderLoad',false,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
  //       return ''
  //     });
  //     return jsTagSrc
  // }


  // const fetchFooterText = async () => {
  //   try {
  //     setShowFooterLoader(true);
  //     var tsTime = new Date(new Date().getTime());
  //     fetch( getConfig().footerApiUrl + currenti18nLanguageCode + '&ts=' + tsTime)
  //       .then(response => response.text())
  //       .then(data => {
  //         const enc2 = data;
  //         const newDocument2 = (new DOMParser).parseFromString(enc2, 'text/html');

  //         var aTags = newDocument2.body.querySelectorAll('.footer-hyperlink-section a');

  //         // console.log("atags",aTags);

  //         for(let i=0; i< aTags.length; i++){
  //           if(aTags[i].href){
  //             // if(aTags[i].href.indexOf(".pdf") != -1 ){
  //             let oldFooterLogoHref = aTags[i].href?.split('//')[1].split('/');
  //             oldFooterLogoHref && oldFooterLogoHref.shift();
  //             aTags[i].href = headerFooterDomain + oldFooterLogoHref?.join('/');
  //           // }
  //           }
  //         }

  //         var imgs = newDocument2.getElementsByTagName("img");

  //         for(let i=0; i< imgs.length; i++){
  //           // if(imgs[i].src.indexOf("http://") == 0 || imgs[i].src.indexOf("https://") == 0){
  //           if(imgs[i].src.indexOf(".svg") != -1){
  //             let oldFooterLogoSRC = imgs[i].src.split('//')[1].split('/');
  //             oldFooterLogoSRC.shift();
  //             imgs[i].src = headerFooterDomain + oldFooterLogoSRC.join('/');
  //           }
  //         }

  //         var sources = newDocument2.getElementsByTagName("source");

  //         for(let i=0; i< sources.length; i++){
  //           if(sources[i].srcset.indexOf(".svg") != -1){
  //             sources[i].srcset = headerFooterDomain + sources[i].srcset;
  //           }
  //         }

  //         // const footerLogo = newDocument2.body.querySelector(".footer-logo img");
  //         // let oldFooterLogoSRC = footerLogo.src.split('//')[1].split('/');
  //         // oldFooterLogoSRC.shift();

  //         // footerLogo.src = headerFooterDomain + oldFooterLogoSRC.join('/')

  //         // const svgs = newDocument2.body.querySelectorAll(".footer-media-section a img");
  //         // for (let i = 0; i < svgs.length; i++) {
  //         //   let oldSRC = svgs[i].src.split('//')[1].split('/');
  //         //   oldSRC.shift();
  //         //   // svgs[i].src = 'https://extqa.ocdx.com/'+ oldSRC.join('/')
  //         //   svgs[i].src = headerFooterDomain + oldSRC.join('/')
  //         // }

  //         const converted2 = newDocument2.body.innerHTML;
  //         setFooter(converted2)
  //         setShowFooterLoader(false);
  //         return converted2
  //       }).catch((error)=>{
  //         setShowFooterLoader(false);
  //         return ''
  //       })
  //   } catch (error) {
  //     setShowFooterLoader(false);
  //   }
  // }

  const trackLogin = async (userInfo) => {
    try {
      const res = await fetch(endpointBaseClick, {
        method: 'post',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Connection': 'keep-alive',
          'Authorization': `Bearer ${search_key} `,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "query": employeeTypes[userInfo["custom:UserType"]], "document_id": "user_tracking", "tags": ["user-login"]
        })
      });
    } catch (error) {
      console.error("error caught while fetching the accessToken from server using RT", error)
    }
  }

  const fetchAWSTokenFromRT = async () => {
    try {
      const res = await fetch(`${oauth_url}`, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Connection': 'keep-alive',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          'grant_type': 'refresh_token',
          'client_id': client_id,
          'refresh_token': Cookies.get("rt")
        }),
      })
      const json = await res.json();
      setTokenExpiryTime(json.expires_in)
      var expiryTokenTime = json.expires_in - 60; //one minute less than actual expiry time
      var expiryTime = new Date(new Date().getTime() + expiryTokenTime * 1000);
      Cookies.set("at", json.access_token, { expires: expiryTime })
      return json.access_token;
    } catch (error) {
      console.error("error caught while fetching the accessToken from server using RT", error)
    }
  }
const GlobeIcon = ({ width = 24, height = 24, fill = '#fff' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      className="bi bi-globe"
      viewBox="0 0 16 16"
    >
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
    </svg>
  )
  const redirectToLogin = () => {
    if (showLoginClicked) {
      let redUrl = login_url.trim() + redirect_uri.trim();
      setShowLoginClicked(false)
      window.location.href = redUrl;
    }
  }
  function categoryParamFn() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams != undefined && urlParams != '') {
      //const id = urlParams.get('tID');
      const id = urlParams.get('category');
      let docKey = '';
      let finData = [];
      if(id){
        setfilterValue(id)
        Cookies.set("docTypeKey", id, { expires: 0.0007 });
      }else {
        setfilterValue('')
        Cookies.set("docTypeKey", '', { expires: 0.0007 });
      }

     /* if (doctype_filter.length > 0) {
        const finData = doctype_filter.filter(e => e.id == id);
        docKey = finData.length > 0 ? finData[0].key : '';
        if (docKey) {
          setfilterValue(docKey)
          Cookies.set("docTypeKey", docKey, { expires: 0.0007 });
          console.log(docKey)
        } else {
          setfilterValue('')
          Cookies.set("docTypeKey", '', { expires: 0.0007 });
        }
      }*/
    }
    else {
      setfilterValue('')
      Cookies.set("docTypeKey", '', { expires: 0.0007 });
    }
  }

  function tidCheckwithParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const countryLangFromURL = extractLangCountryFilterFromURL(location.search)
    if (urlParams != undefined && urlParams != '') {
      const getLang = urlParams.get('language') || (countryLangFromURL?.language !== "null" ? countryLangFromURL?.language : "" );
      const getCoun = urlParams.get('country') || (countryLangFromURL?.country !== "null" ? countryLangFromURL?.country : "") ;
      const getSearchTrm = urlParams.get('search-term');
      const getDocId = urlParams.get('docId') || urlParams.get('q');
      // const audience = urlParams.get('audience');
      let computeCntry = (Cookies.get("Techdocs-Country") && !getCoun) ? "" : getCoun
      let computeLng = (Cookies.get("Techdocs-Language") && !getLang) ? "" : getLang

      if(Cookies.get("manualLogin") === "true"){
        if (getCoun){
            Cookies.set("Techdocs-Country", computeCntry, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
            Cookies.set("Techdocs-Temp-Country", computeCntry, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
            setCountrySelected(computeCntry)
        }
      }
      else{
        Cookies.set("Techdocs-Country", computeCntry, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        Cookies.set("Techdocs-Temp-Country", computeCntry, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        setCountrySelected(computeCntry)
      }

      if(Cookies.get("manualLogin") === "true"){
        if (getLang ){
            Cookies.set("Techdocs-Language", computeLng, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
            Cookies.set("Techdocs-Temp-Language", computeLng, { expires: new Date(new Date().getTime() +    Cookie_expiry_time) })
            setLanguageSelected(computeLng)
        }
      }else{
        Cookies.set("Techdocs-Language", computeLng, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        Cookies.set("Techdocs-Temp-Language", computeLng, { expires: new Date(new Date().getTime() +    Cookie_expiry_time) })
        setLanguageSelected(computeLng)
      }
      if(getSearchTrm)
      {
        Cookies.set("search-term", getSearchTrm, { expires: 0.0007 });
        setSrchTrm(getSearchTrm);
      }
      if(getDocId){
        Cookies.set("docId", getDocId, { expires: 0.0007 });
        setSrchTrm(getDocId);
        searchFields["id"] = {};
       
      }
      // if(audience){
      //   Cookies.set("audience", audience, { expires: 0.0007 });
      //   setAudience(audience);
      // }
    } 
  }

  const handleClose = () => {
    if (Cookies.get("Techdocs-Temp-Language") != null && Cookies.get("Techdocs-Language") != null && Cookies.get("Techdocs-Temp-Language") !== Cookies.get("Techdocs-Language")) {
      Cookies.set("Techdocs-Temp-Language", Cookies.get("Techdocs-Language"), { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
      setLanguageSelected(Cookies.get("Techdocs-Language"))
    }
    if (Cookies.get("Techdocs-Temp-Country") != null && Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Temp-Country") !== Cookies.get("Techdocs-Country")) {
      Cookies.set("Techdocs-Temp-Country", Cookies.get("Techdocs-Country"), { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
      setCountrySelected(Cookies.get("Techdocs-Country"))
    }

    setShow(false);
  }

  const handleShow = () => setShow(true);

  const acceptCookie = (value) => {
    setShowCookieDisclaimer(value)
  }

  const readCookie = () => {
    const country = Cookies.get("Techdocs-Country");
    const language = Cookies.get("Techdocs-Language")
    setResultsPerPageSize(Cookies.get('resultsPerPageSize') ? parseInt(Cookies.get('resultsPerPageSize')) : resultsPerPagE);
    if(!Cookies.get('showCollapse'))
      Cookies.set('showCollapse',Boolean(true),{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
      Cookies.set('showToggle',Boolean(false),{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
    const cookieAccepted = Cookies.get("Techdocs-acceptance")
    if (cookieAccepted != null && cookieAccepted === "true")
      setShowCookieDisclaimer(false)
    else
      setShowCookieDisclaimer(true)

    if (country === 'ALL' && language !== 'ALL' && !Cookies.get("rt")) {
      setShow(true)
      Cookies.remove("Techdocs-Country");
      Cookies.remove("Techdocs-Temp-Country");
      setCountrySelected(null)
    }
   else if (language === 'ALL' &&  country !== 'ALL' && !Cookies.get("rt")) {
      setShow(true)
      Cookies.remove("Techdocs-Language");
      Cookies.remove("Techdocs-Temp-Language");
      setLanguageSelected(null)
    }
    else if (language === 'ALL' && country === 'ALL' && !Cookies.get("rt")) {
      setShow(true)
      Cookies.remove("Techdocs-Language");
      Cookies.remove("Techdocs-Temp-Language");
      Cookies.remove("Techdocs-Country");
      Cookies.remove("Techdocs-Temp-Country");
      setCountrySelected(null)
      setLanguageSelected(null)
    }
    else if (country && language) {
        setShow(false)
      }
      else {
        setTimeout(() => {
          let ck = Cookies.get("IsheaderLoad")
          if (ck == "false") {
            setShow(true)
          }

        }, 1000);
        setCountrySelected(country)
        setLanguageSelected(language)
      }
  }

  const doPollForATValidation = async () => {
    if (!Cookies.get("at") && Cookies.get("rt")) {
      const accessToken = await fetchAWSTokenFromRT();
      setAccessToken(accessToken)
    }
    if (Cookies.get("rt")) {
      var expiryTime = (tokenExpiryTime - 30) * 1000; //30 seconds after the actual cookie expires 
      setTimeout(function () { window.location.reload(false)/*doPollForATValidation()*/ }, expiryTime)
    }
  }

  function getUpdatedResults(results) {
    var newResults = [results.length]
    results.map((result, index) =>
      newResults[index] =
      {
        ...result,
        selected: result.selected ? result.selected : { raw: false }
      }
    )
    return newResults;
  }

  function optionClickAdd(option) {
    let newOptions = []
    docClass.forEach((doc, index) => {
      if (doc.value === option.value) {
        newOptions[index] = { count: option.count, value: option.value, selected: true }
      }
      else {
        newOptions[index] = doc
      }
    })
    setDocClass([...newOptions])
  }

  const resetDocClasses = () => {
    if (Cookies.get('product')) {
      Cookies.set("product", '', { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
    }
    let newOptions = []
    docClass.forEach((doc, index) => {
      newOptions[index] = { count: doc.count, value: doc.value, selected: false }
    })
    setDocClass([...newOptions])
  }

  function optionClickRemove(option) {
    let newOptions = []
    docClass.forEach((doc, index) => {
      doc.value === option.value ? newOptions[index] = option : newOptions[index] = doc
    })
    setDocClass([...newOptions])
  }

  const getURL = async (id, fileType, name) => {
    let url = userLoggedIn ? auth_download_url : download_url;
    let auth_header = userLoggedIn ? access_Token : search_key;
    const urlToReturn = await fetchURL(`${url}?filename=${id}/${name}`, auth_header);
    return urlToReturn;
  }

  const fetchURL = async (url, auth_header) => {
    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${auth_header}`
        }
      })
      const data = await res.json();
      const downloadurl = data[0].downloadurl;
      const fileName = data[0].fileName;
      return downloadurl; //+ `/${fileName}`;
    } catch (error) {
      console.error("error while fetching the url", url + " error is " + error)
    }
  }

  async function downloadSelected(event, downloadAll_fileName) {
    event.preventDefault();
    const filesToDownload = [resultsSelected.map(res => res.id)];
    const urls = []
    resultsSelected.map((r, index) => {
      const url = r.url;
      urls[index] = url;
      return r;
    })
    const zip = new JSZip();
    let count = 0;
    const zipFilename = downloadAll_fileName + ".zip";
    resultsSelected.map(async function (result) {
      let url = result.url + ''
      const filename = result.name
      try {
        const file = await JSZipUtils.getBinaryContent(url)
        zip.file(filename, file, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
        setLoad(false)
      } catch (error) {
        setLoad(false)
      }
    });
  }

  const getConditionalConfig = () => {
    tidCheckwithParams()
    categoryParamFn()
    if (countrySelected && languageSelected && Cookies.get("at") === undefined && Cookies.get("placeHolder") === undefined) {
      //console.log("**********executing search")
      let allFilters = [
        { field: "regioncountrylist", values: [countrySelected], type: "any" },
        { field: "language", values: [languageSelected, "Multiple Languages"], type: "any" },
        { field: "audience", values: ["Public"], type: "any" },
        { field: "expirationdate", values: [expirationFilter], type: "any" }
      ];

      allFilters = filterValue ? [...allFilters, { field: 'document_classification', values: [filterValue], type: 'any' }] : [...allFilters];

      return (
        {
          apiConnector: connector,
          autocompleteQuery: autocompleteQuery,
          searchQuery: {
            search_fields: searchFields,
            result_fields: resultFields,
            group: group,
            facets: facets,
            disjunctiveFacets: disjunctiveFacets,
          },
          initialState: {
            alwaysSearchOnInitialLoad: true,
            resultsPerPage: resultsPerPageSize,
            searchTerm: srchTrm,
            sort: sortFields,
            filters: getUniqueArray(allFilters, getFiltersFromUrl(), "field", "public")
          },
          trackUrlState: trackUrlState,
          debug: debug,
          userLogged: userLoggedIn,
        }
      )
    }
    else
      if (!countrySelected && !languageSelected) {
        //console.log('*** in else condition')
        return (
          {
            apiConnector: connector,
            autocompleteQuery: autocompleteQuery,
            searchQuery: {
              search_fields: searchFields,
              result_fields: resultFields,
              group: group,
              // 3. Facet by language, region, country, documentClassification, subClassification, businessLine, reagentList and equipmentlist (used by Filters)
              facets: facets,
              disjunctiveFacets: disjunctiveFacets,
              //precision: precision,
            },
            initialState: {
              alwaysSearchOnInitialLoad: true,
              resultsPerPage: resultsPerPageSize,
              searchTerm: srchTrm,
              sort: sortFields,
              filters: getUniqueArray([{ field: "expirationdate", values: [expirationFilter], type: "any" }], getFiltersFromUrl(), "field", "public")
            },
            trackUrlState: trackUrlState,
            debug: debug,
            userLogged: userLoggedIn,
          }
        )
      }
  }

  const getSecureConfig = (connector, audience, isDistributor) => {
    tidCheckwithParams()
    categoryParamFn()
    //console.log(" Country does not contain ALL ", Cookies.get("Techdocs-Country") !== 'ALL')

    if (Cookies.get("Techdocs-Country") !== 'ALL' && Cookies.get("Techdocs-Language") !== 'All' && !audience.includes("Internal")) {
      let secConfigAllFields = [
        { field: "regioncountrylist", values: [countrySelected], type: "any" },
        { field: "language", values: [languageSelected, "Multiple Languages"], type: "any" },
        { field: "audience", values: [...audience], type: "any" },
        { field: "expirationdate", values: [expirationFilter], type: "any" }
      ];
      secConfigAllFields = filterValue ?
        [...secConfigAllFields, { field: 'document_classification', values: [filterValue], type: 'any' }] : [...secConfigAllFields];
      return (
        {
          apiConnector: connector,
          autocompleteQuery: autocompleteQuery,
          searchQuery: {
            search_fields: searchFields,
            result_fields: resultFields,
            group: group,
            // 3. Facet by language, region, country, documentClassification, subClassification, businessLine, reagentList and equipmentlist (used by Filters)
            facets: facets,
            disjunctiveFacets: disjunctiveFacets,
            //precision: precision,
          },
          initialState: {
            alwaysSearchOnInitialLoad: true,
            resultsPerPage: resultsPerPageSize,
            searchTerm: srchTrm,
            sort: sortFields,
            filters: getUniqueArray(secConfigAllFields, getFiltersFromUrl(), "field", "internal"),
          },
          trackUrlState: trackUrlState,
          debug: debug,
          userLogged: userLoggedIn,
        }
      )
    }
    else {
      if (Cookies.get("Techdocs-Country") === 'ALL' && Cookies.get("Techdocs-Language") !== 'All' && !audience.includes("Internal")) {
        let filtersOptions = [
          { field: "language", values: [languageSelected, "Multiple Languages"], type: "any" },
          { field: "audience", values: [...audience], type: "any" },
          { field: "expirationdate", values: [expirationFilter], type: "any" }
        ];
        filtersOptions = filterValue ?
          [...filtersOptions, { field: 'document_classification', values: [filterValue], type: 'any' }] : [...filtersOptions];
        return (
          {
            apiConnector: connector,
            autocompleteQuery: autocompleteQuery,
            searchQuery: {
              search_fields: searchFields,
              result_fields: resultFields,
              group: group,
              // 3. Facet by language, region, country, documentClassification, subClassification, businessLine, reagentList and equipmentlist (used by Filters)
              facets: facets,
              disjunctiveFacets: disjunctiveFacets,
              //precision: precision,
            },
            initialState: {
              alwaysSearchOnInitialLoad: true,
              resultsPerPage: resultsPerPageSize,
              searchTerm: srchTrm,
              sort: sortFields,
              filters: getUniqueArray(filtersOptions, getFiltersFromUrl(), "field", "internal"),
            },
            trackUrlState: trackUrlState,
            debug: debug,
            userLogged: userLoggedIn,
          }
        )
      } else if(Cookies.get("Techdocs-Country") !== 'ALL' && Cookies.get("Techdocs-Language") === 'All' && !audience.includes("Internal")) {
        let filtersOptions = [
          { field: "regioncountrylist", values: [countrySelected], type: "any" },
          { field: "audience", values: [...audience], type: "any" },
          { field: "expirationdate", values: [expirationFilter], type: "any" }
        ];
        filtersOptions = filterValue ?
          [...filtersOptions, { field: 'document_classification', values: [filterValue], type: 'any' }] : [...filtersOptions];
          return (
            {
              apiConnector: connector,
              autocompleteQuery: autocompleteQuery,
              searchQuery: {
                search_fields: searchFields,
                result_fields: resultFields,
                group: group,
                // 3. Facet by language, region, country, documentClassification, subClassification, businessLine, reagentList and equipmentlist (used by Filters)
                facets: facets,
                disjunctiveFacets: disjunctiveFacets,
                //precision: precision,
              },
              initialState: {
                alwaysSearchOnInitialLoad: true,
                resultsPerPage: resultsPerPageSize,
                searchTerm: srchTrm,
                sort: sortFields,
                filters: getUniqueArray(filtersOptions, getFiltersFromUrl(), "field", "internal"),
              },
              trackUrlState: trackUrlState,
              debug: debug,
              userLogged: userLoggedIn,
            }
          )
      } else if(Cookies.get("Techdocs-Country") === 'ALL' && Cookies.get("Techdocs-Language") === 'All' && !audience.includes("Internal")) {
        let filtersOptions = [
          { field: "audience", values: [...audience], type: "any" },
          { field: "expirationdate", values: [expirationFilter], type: "any" }
        ];
        filtersOptions = filterValue ?
          [...filtersOptions, { field: 'document_classification', values: [filterValue], type: 'any' }] : [...filtersOptions];
          return (
            {
              apiConnector: connector,
              autocompleteQuery: autocompleteQuery,
              searchQuery: {
                search_fields: searchFields,
                result_fields: resultFields,
                group: group,
                // 3. Facet by language, region, country, documentClassification, subClassification, businessLine, reagentList and equipmentlist (used by Filters)
                facets: facets,
                disjunctiveFacets: disjunctiveFacets,
                //precision: precision,
              },
              initialState: {
                alwaysSearchOnInitialLoad: true,
                resultsPerPage: resultsPerPageSize,
                searchTerm: srchTrm,
                sort: sortFields,
                filters: getUniqueArray(filtersOptions, getFiltersFromUrl(), "field", "internal"),
              },
              trackUrlState: trackUrlState,
              debug: debug,
              userLogged: userLoggedIn,
            }
          )
      }
      else if (audience.includes("Internal")) {
        var isCountryAllSelected = Cookies.get("Techdocs-Country") && Cookies.get("Techdocs-Country") === 'ALL';
        var isLanguageAllSelected = Cookies.get("Techdocs-Language") && Cookies.get("Techdocs-Language") === 'ALL';
    var filterString = [];
    if(isCountryAllSelected && !isLanguageAllSelected){
      filterString = [{ field: "language", values: [languageSelected, "Multiple Languages"], type: "any" }, 
                      { field: "audience", values: [...audience], type: "any" }]
    } else if(isLanguageAllSelected && !isCountryAllSelected) {
      filterString = [{ field: "audience", values: [...audience], type: "any" },
                      { field: "regioncountrylist", values: [countrySelected], type: "any" }];
    } else if(isCountryAllSelected && isLanguageAllSelected){
      filterString = [{ field: "audience", values: [...audience], type: "any" }]
    } else {
      filterString = [{ field: "language", values: [languageSelected, "Multiple Languages"], type: "any" }, 
                      { field: "audience", values: [...audience], type: "any" }, 
                      { field: "regioncountrylist", values: [countrySelected], type: "any" }]
    }
    filterString = [...filterString, { field: "expirationdate", values: [expirationFilter], type: "any" }]
    filterString = filterValue ?
      [...filterString, { field: 'document_classification', values: [filterValue], type: 'any' }] : [...filterString];

        if (!isDistributor)        
          return (
            {
              apiConnector: connector,
              autocompleteQuery: autocompleteQuery,
              searchQuery: {
                search_fields: searchFields,
                result_fields: resultFields,
                group: group,
                // 3. Facet by language, region, country, documentClassification, subClassification, businessLine, reagentList and equipmentlist (used by Filters)
                facets: facets,
                disjunctiveFacets: disjunctiveFacets,
                //precision: precision,
              },
              initialState: {
                alwaysSearchOnInitialLoad: true,
                resultsPerPage: resultsPerPageSize,
                searchTerm: srchTrm,
                sort: sortFields,
                filters: getUniqueArray(filterString, getFiltersFromUrl(), "field", "internal"),
              },
              trackUrlState: trackUrlState,
              debug: debug,
              userLogged: userLoggedIn,
            }
          )
        else {
          let initialFilterOptions = [
            { field: "regioncountrylist", values: [countrySelected], type: "any" },
            { field: "language", values: [languageSelected, "Multiple Languages"], type: "any" },
            { field: "audience", values: [...audience], type: "any" },
            { field: "usage_type", values: ["Distributor", ""], type: "any" },
            { field: "expirationdate", values: [expirationFilter], type: "any" }
          ];
          initialFilterOptions = filterValue ?
            [...initialFilterOptions, { field: 'document_classification', values: [filterValue], type: 'any' }] : [...initialFilterOptions];
          return (
            {
              apiConnector: connector,
              autocompleteQuery: autocompleteQuery,
              searchQuery: {
                search_fields: searchFields,
                result_fields: resultFields,
                group: group,
                // 3. Facet by language, region, country, documentClassification, subClassification, businessLine, reagentList and equipmentlist (used by Filters)
                facets: facets,
                disjunctiveFacets: disjunctiveFacets,
                //precision: precision,
              },
              initialState: {
                alwaysSearchOnInitialLoad: true,
                resultsPerPage: resultsPerPageSize,
                searchTerm: srchTrm,
                sort: sortFields,
                filters: getUniqueArray(initialFilterOptions, getFiltersFromUrl(), "field", "internal"),
              },
              trackUrlState: trackUrlState,
              debug: debug,
              userLogged: userLoggedIn,
            }
          )

        }
      }
    }
  }
  // const getFilters = () => {
  //   if (countrySelected && languageSelected)
  //     return (
  //       [
  //         { field: 'regioncountrylist', values: [countrySelected] },
  //         { field: 'language', values: [languageSelected] }
  //       ])
  //   else
  //     if (countrySelected)
  //       return ([
  //         { field: 'regioncountrylist', values: [countrySelected] }
  //       ])
  //     else if (languageSelected)
  //       return (
  //         [
  //           { field: 'language', values: [languageSelected] }
  //         ])
  //     else
  //       return ([
  //         { field: 'regioncountrylist', values: ['United States of America'] },
  //         { field: 'language', values: ['English'] }
  //       ])
  // }

  const handleOnResultChange = async (id, fileType, name, isUserAuthenticated, access_Token) => {
    if (resultsSelected.length === 0) {
      var url = await getURL(id, fileType, name)
      var newItem = { id: id, fileType: fileType, name: name, url: url }
      setResultsSelected([...resultsSelected, newItem]);
      return;
    }
    else {
      const updatedResultsSelected = resultsSelected.filter(item => item.id !== id)

      if (updatedResultsSelected.length === resultsSelected.length) //nothing is found
      {
        var url = await getURL(id, fileType, name)
        var newItem = { id: id, fileType: fileType, name: name, url: url }
        setResultsSelected([...resultsSelected, newItem])
      }
      else
        setResultsSelected([...updatedResultsSelected]);
    }
  };

  function getTotalResultsCount(document_classification, filters, totalcount, isDistributor) {
    Cookies.set("cat", "", { expires: new Date(new Date().getTime() + Cookie_expiry_time) });
    Cookies.set("subcat", "", { expires: new Date(new Date().getTime() + Cookie_expiry_time) });  
    setTotalResultCount(totalcount);
    if (totalcount && totalcount < 10000 && filters.length != 0)
      return totalcount;
    let totalCount = 0;
    if(!userToken)
    setUserToken(false)
    document_classification.map(s => {
      var data = s.data;
      data.forEach(element => {
        if (filters.length <= 4 & !isDistributor) {
          totalCount += element.count;
        }
        else
          // if (filters.length <= 5 && isDistributor) {
          if (filters.length <= 5) {
            totalCount += element.count;
          }
          else
            filters.map(f => {
              if (f.values.indexOf(element.value) != -1) {
                totalCount += element.count;
              }
            })
        return element;
      })
    });
    setTotalResultCount(-1);
    return totalCount;
  }

  function onChangeCountry(value) {
    Cookies.set("Techdocs-Temp-Country", value, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
    const currentCountrytoBeSet = { ["count"]: 0, ["value"]: value, ["selected"]: true }
    let prevCountry = countrySelected;
    const prevCountryToBeReSet = { ["count"]: 0, ["value"]: prevCountry, ["selected"]: false }
    setCountrySelected(value)
  }

  function onChangeLanguage(value) {
    Cookies.set("Techdocs-Temp-Language", value, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
    setLanguageSelected(value)
  }

  const connector = new AppSearchAPIConnector({
    searchKey: userLoggedIn ? `${access_Token}` : search_key,
    engineName,
    endpointBase: userLoggedIn ? auth_url : public_url,
    beforeSearchCall: (existingSearchOptions, next) => {
      return next({
        ...existingSearchOptions,
        analytics: {
          tags: ["Initial_call_no_search"]
        }
      })
    }

  });


  //Fetch Languages
  const fetchLanguages = async (employed) => {
    const res = await fetch(`${lang_url}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${search_key}`
      }
    }).then(response => response.json())
      .then(json => {
        var languagesFromServer = json.myRows;
        let lnges = []
        let cookieLanguage = Cookies.get("Techdocs-Language");
        // if (languagesFromServer != null && !languagesFromServer.find(({ value }) => value === 'ALL')) {
        //   languagesFromServer = [{ count: 0, value: 'ALL', selected: false }, ...languagesFromServer]
        // }
        if (languagesFromServer !== undefined)        
          languagesFromServer.filter(l => l.OTD_Language != null).map((l, index) => {
            let name = l.OTD_Language
            if (l.OTD_Language != null && l.OTD_Language != 'Multiple Languages') {
              if (l.OTD_Language !== 'ALL' || employed) {
              let selected = cookieLanguage === name ? true : false
              if (selected)
                console.log("Language selected is " + selected + " and is " + name + " cookie value " + cookieLanguage)
              lnges[lnges.length] = { "count": 0, "value": name, "selected": selected }
            }
          }
            return l;
          })
          if (employed && languages != null && !languages.find(({ value }) => value === 'ALL')) {
            languagesFromServer = [{ count: 0, value: 'ALL', selected: false }, ...languagesFromServer]
          }
        else {
          // console.log("languagesFromServer empty ", isEmployee(userInfo))
        
          lnges = languagesJSON;
          lnges = lnges.filter(c => (c.value != 'ALL' || employed)).map(c => {
              c.selected = c.value === cookieLanguage
              return c;
            })
          

          // lnges = languagesJSON;
          // lnges = lnges.map(l => {
          //   l.selected = l.value === cookieLanguage ? true : false;
          //   return l;
          // })
        }
        lnges = lnges.sort((a, b) => {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        })
        lnges = JSON.parse(JSON.stringify(lnges))
        //console.log("mod lnges ", lnges)
        /*lnges.map( lng => {
          console.log(lng)
        })*/
        console.log("emplued", employed)
       
        setLanguages(lnges)
      })
      .catch(error => {
        console.error('Request failed languages', error)
        return languagesJSON; // the static list from the engine.json
      })
  }

  //Fetch Countries
  const fetchCountries = async (employed) => {
    const res = await fetch(`${country_url}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${search_key}`
      }
    }).then(response => response.json())
      .then(json => {
        var countriesFromServer = json.myRows;
        let cntries = []
        let cookieCountry = Cookies.get("Techdocs-Country");
        if (countriesFromServer != undefined) {
          countriesFromServer = countriesFromServer.filter(c => c.OTD_Country != null).map((c, index) => {
            let name = c.OTD_Country
            if (c.OTD_Country !== 'ALL' || employed) {
              //console.log("*****All selecetd ", c.OTD_Country === 'ALL')
              let selected = cookieCountry === name? true : false;
              if (selected)
                // console.log("country selected is " + selected + " and is " + name + " cookie value " + cookieCountry)
              cntries[cntries.length] = { "count": 0, "value": name, "selected": selected }
            }
            return c;
          })
          if (employed && countries != null && !countries.find(({ value }) => value === 'ALL')) {
            countriesFromServer = [{ count: 0, value: 'ALL', selected: false }, ...countriesFromServer]
          }
        }
        else {
          cntries = countriesJSON;
          cntries = cntries.filter(c => (c.value != 'ALL' || employed)).map(c => {
            c.selected = c.value === cookieCountry
            return c;
          })
        }
        cntries = cntries.sort((a, b) => {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        })        
        cntries = JSON.parse(JSON.stringify(cntries))
        // setCountries(cntries)
        return cntries;
      })
      .catch(error => {
        console.error('Request failed countries', error)
        return countriesJSON; // the static list from the engine.json
      })
  }

  const fetchAltDispValues = async () => {
    try {
      const res = await fetch(`${altDisplay_url}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${search_key}`
        }
      })
      const data = await res.json()
      return data.myRows
    } catch (error) {
      console.error("error occurred while fetchingAlt dispValues")
    }
  }

  const fetchUserInfo = async (aToken) => {
    try {
      const res = await fetch(`${oauth_userInfo_url}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${aToken}`
        }
      })
      const data = await res.json();
      return data
    } catch (error) {
      // console.log("error while fetching userInfo from server ", error)
    }
  }

  const fetchAccessToken = async (code) => {
    console.log("redirect_uri",redirect_uri)
    try {
      const res = await fetch(`${oauth_url}`, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Connection': 'keep-alive',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          'grant_type': 'authorization_code',
          'client_id': client_id,
          'code': code,
          'redirect_uri': redirect_uri
        }),
      })
      const data = await res.json()
      if (data) {
        var expiryTokenTime = data.expires_in - 60; //one minute less than actual expiry time
        var expiryTime = new Date(new Date().getTime() + expiryTokenTime * 1000);
        setTokenExpiryTime(data.expires_in)
        //var refreshExpTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        if (data.access_token)
          Cookies.set("at", data.access_token, { expires: expiryTime })
        if (data.refresh_token)
          Cookies.set("rt", data.refresh_token, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
        return data.access_token
      }
      else
        console.error("data from fetchAccessToken is null")
    } catch (error) {
      console.error("error occurred while fetching the accessToken ", error)
    }
  }

  function uniqueByKey(array, key) {
    return [...new Map(array.map((x) => [x[key], x])).values()];
  }

  // const openModal = () => {
  //   setShowModal(prev => !prev)
  // }

  function getAudienceType(userInfo) {
    var audience = null;
    if (Cookies.get("at") === undefined)
      audience = ["Public"];
    else {
      var employee = isEmployee(userInfo)
      var distributor = isDistributorFn(userInfo)
      audience = (employee || distributor) ? ["Public", "Internal", "Authenticated"] : ["Public", "Authenticated"]

    }
    return audience;
  }

  function isDistributorFn(userInfo) {
    var distributor = false;
    //if (userInfo && userInfo["custom:UserType"] === 'D')
    if (userInfo && (userInfo["custom:UserType"] === 'D' || userInfo["custom:FeatureList"] && userInfo["custom:FeatureList"].indexOf("isDistributor") !== -1))
      distributor = true;
    return distributor;

  }

  function isEmployee(userInfo) {
    var employee = false;
    if (userInfo && userInfo["custom:UserType"] === 'E')
      employee = true;
    //console.log('isEmployee ' + employee)
    return employee;
  }


  function isEmployeeOrDistributor(userInfo) {
    var employeeOrDistributor = false;
    //if (userInfo && (userInfo["custom:UserType"] === 'E' || userInfo["custom:UserType"] === 'D'))
    if (userInfo && (userInfo["custom:UserType"] === 'E' || userInfo["custom:UserType"] === 'D') || (userInfo["custom:FeatureList"] && userInfo["custom:FeatureList"].indexOf("isDistributor") !== -1))
      employeeOrDistributor = true;
    //console.log('isEmployeeOrDistributor ' + employeeOrDistributor)
    return employeeOrDistributor;
  }  



  const searchClick = (id) => {
    // delete searchFields.id;
    setResultsSelected([]);
  }

  const sortFacetValues = (context, category, categoryClass) => {
    if (!category) return context;
    return {
      ...context,
      facets: {
        ...(context.facets || {}),
        categoryClass: category.map(s => ({
          ...s,
          data: s.data.sort((a, b) => {
            if (a.value > b.value) return 1;
            if (a.value < b.value) return -1;
            return 0;
          })
        }))
      }
    };
  }

  // const areFilterSelected = (filters, categories) => {
  //   //console.log('filters are called ', filters.filters)
  //   //console.log("categories", categories)
  //   let count = categories.length;

  //   if (filters && filters.filters && filters.filters.length < 0)
  //     return false;
  //   categories.map(category => {
  //     filters.filters.forEach(filter => {
  //       //  console.log('filter', filter) 
  //       //console.log('category', category)
  //       if (filter.field === category) {
  //         // console.log('matches', filter.field + " = " + category)
  //         count--;
  //       }
  //     })
  //   })
  //   //console.log('count after iteration ', count)
  //   if (count === 0) {
  //     //console.log("All criteria met and hence the widget is goot to be shown return true ")
  //     return true
  //   }
  //   else {
  //     //console.log("All criteria did not meet and hence the widget is not going to be shown returning false ")
  //     return false;
  //   }
  // }

  const handleShowLogin = () => {
    handleShow();
    setShowLoginClicked(true);
  }

  const [config, setConfig] = useState('');
  const config2 = useMemo(() => getConditionalConfig(),[filterValue, srchTrm]);
  //const [config, setConfig] = useState(getConditionalConfig());
  //config || config2

  const toggleMenu = (val) => {
    const menuToggle = val
    if (menuToggle === true) {
      document.getElementById('test').style.display = 'block'
      document.getElementById('collapse-filter').style.display = 'block'
      Cookies.set('show-filter', 'show something', { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
      document.getElementById('show-filter').style.display = 'none'

    } else {
      document.getElementById('test').style.display = 'none'
      document.getElementById('collapse-filter').style.display = 'none'
      document.getElementById('show-filter').style.display = 'block'
      Cookies.set('show-filter', '', { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
    }
  }

if(Cookies.get('show-filter') !== '' && document.getElementById('test') && window.innerWidth < 500){
  toggleMenu(true)
}


const handlePageChange = (onChange, ...args) => {
  onChange(...args);
  // setTimeout(()=>{
    window.scrollTo({top:0, behavior:"smooth"})
  // },500)
}


const handleExpiredDocs = (e, removeFilter, setFilter)=>{
  console.log(e.target.checked);
  setExpirySearch(e.target.checked);
  if(e.target.checked){
    removeFilter('expirationdate');
  }else{
    setFilter('expirationdate', expirationFilter,'any')
  }
}

  return (
    <AppRoot access_token={access_Token} key={access_Token} >
      <SearchProvider config={config || config2} key={access_Token} accessToken={access_Token} userLogged={userLoggedIn}>
        <ModalProvider>
          <WithSearch mapContextToProps={({ addFilter, removeFilter, wasSearched, isLoading, results, filters, clearFilters, setFilter, searchTerm, setSearchTerm, resultSearchTerm, facets, trackClickThrough, setResultsPerPage, resultsPerPage }) => ({ addFilter, removeFilter, wasSearched, isLoading, results, filters, clearFilters, setFilter, searchTerm, setSearchTerm, resultSearchTerm, facets, trackClickThrough, setResultsPerPage, resultsPerPage })}>
            {({ addFilter, removeFilter, wasSearched, isLoading, results, filters, clearFilters, setFilter, searchTerm, setSearchTerm, resultSearchTerm, facets, trackClickThrough, setResultsPerPage, resultsPerPage }) => {
              //setCustomFilter(addFilter)
              // console.log("docClass ***App ", docClass)
              /* if (docClass && docClass.length === 0 && facets && facets.document_classification && facets.document_classification[0].data != undefined) {
                 let docClasses = [];
                 facets.document_classification[0].data.map((doc, index) => {
                   docClasses[index] = { "count": 0, "value": doc.value, "selected": false }
                 })
                 console.log("***********setting docClasses ", docClasses)
                 setDocClass(docClasses)
               }*/
               {/* addFilter('business_line',['Automation'],'any') */}
               
               if(filters?.length > 1) {
                {/* const subCatList = ["document_classification_subcategory", "equipmentlist", "reagentlist"]
                const checkSubCat = filters?.some(obj => subCatList?.includes(obj.field)) */}
                let filterFields = ["document_classification",  "document_classification_subcategory", "equipmentlist", "reagentlist"]
               filters?.map((item) => {
                if(filterFields.includes(item.field) ) {
                  setIsClearFilter(true)
                } else
                setIsClearFilter(false)
                
               })

               {/* checkSubCat && setIsClearFilter(checkSubCat) */}

              }
              if (results.length > 0 && !userToken) {
                setUserToken(true)
              }
              if (userToken)

              {/* main  */ }
              return (
                <ErrorBoundary view={TechDocsError}>
                  {/* <div id="headerText" dangerouslySetInnerHTML={{ __html: header }}></div> */}
                  {/* { (results.length <= 0 ) && <Loader /> } */}
                  {load && <Loader />}
                  {load && <Loader /> }
                  { redirectSpinner && <Loader /> }
                  {/* {load && <Loader />} */}
                  {(results.length <= 0 && totalResultCount != -1 && isLoading) && <Loader />}
                  {/* {(results.length <= 0 && totalResultCount != -1) && <Loader />} */}
                  {userToken && (
                    <div>
                      {/*showCookieDisclaimer && <OrthoCookie t={t} acceptCookie={acceptCookie} />*/}

                      {/* <div id="headerText" className="aem-header" dangerouslySetInnerHTML={{ __html: header }}></div> */}


                      {/* header for desktop  */}
                      <header id='globalNav' className='global-header' style={{ position: 'relative' }}>
                        <nav className="top-nav">
                        
                          <a href={headerFooterDomain}><img className='td-ortho-logo' src={require('./assets/OrthoLogo_WHT.svg').default}  alt="Ortho Logo" /></a>

                          <div className="container text-right headerProfileText">
                            <div className="language-select">
                              <div className="d-flex justify-content-begin align-items-center language-select-root float-right">
                                <div className="dropdown" style={{ marginTop: '1px' }}>
                                  <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                    <GlobeIcon /><span style={{ padding: '0.25rem 1rem' }}>{currenti18nLanguageName}</span>
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                      <span className="dropdown-item-text">{t('language')}</span>
                                    </li>
                                    {i18nLanguages.map(({ code, name, country_code }) => (
                                      <li key={`${country_code}-${code}`}>
                                        <a
                                          href="#"
                                          className={classNames('dropdown-item', { disabled: currenti18nLanguageCode === code, })} style={{ color: '#212529' }}
                                          onClick={() => {
                                            i18next.changeLanguage(code)
                                            window.location.reload();
                                          }}
                                        >
                                          <span
                                            className={`flag-icon flag-icon-${country_code} mx-2`}
                                            style={{
                                              opacity: currenti18nLanguageCode === code ? 0.5 : 1,
                                            }}
                                          ></span>
                                          {name}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            {userLoggedIn &&
                              <span className="mb-0 mt-1 welcome">{t('welcome_message')} {userInfo.given_name},{userInfo.family_name}</span>
                            }
                            {(showLogin && Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null) &&
                              <a href={`${login_url}${redirect_uri}`} onClick={() => { Cookies.set("placeHolder", "placeHolder", { expires: 0.0007 }); Cookies.set("manualLogin", "true", { expires: new Date(new Date().getTime() + 1 * 60 * 1000) }); return true }} style={{ fontSize: "14px", cursor: "pointer" }}>{t('login')}</a>
                            }
                            {(showLogin && (Cookies.get("Techdocs-Country") === undefined || Cookies.get("Techdocs-Language") === undefined)) &&
                              <a onClick={() => handleShowLogin()} style={{ fontSize: "14px", cursor: "pointer" }}>{t('login')}</a>
                            }
                            <react-comment variant="primary" onClick={handleShow} style={{ color: "#fff", border: '0px' }} className="LangCountryButton">
                              {/*t('langCountry')*/}
                            </react-comment>
                            {userLoggedIn &&
                              <a href={`${logout_url}${redirect_uri}`} style={{ fontSize: "14px" }} onClick={() => { Cookies.remove("at"); Cookies.remove("rt"); Cookies.remove("placeHolder"); Cookies.remove("redirectURL"); Cookies.remove("count"); Cookies.remove("manualLogin"); return true }}>{t('logout')}</a>
                            }

                            {
                              userLoggedIn &&
                              isEmployee(userInfo) &&
                              <FaqLink />
                            }
                            
                          </div>

                          {countries && countries.length > 0 &&  !isLoading && languages && languages.length > 0 &&  <ModalDropdown setShow={setShow}  show={show} userInfo={userInfo} countrySelect={countrySelected} setCountrySelect={setCountrySelected} languageSelect={languageSelected} setLanguageSelect={setLanguageSelected} country={countries} language={languages} setFilterData={setFilter} removeFilt={removeFilter} clearFilter={clearFilters}
                            setResultSelect={setResultsSelected} />  
                         }


                        
                          {/* <Modal className={styles.modal} show={show} onHide={handleClose} style={{ padding: "20px !important", zIndex: "10000" }}>
                           
                            <Modal.Header className={styles.modalHeader}>
                              <Modal.Title className={styles.modalTitle}>
                                <div><GlobeIcon /></div>
                                <div>{t('Select_country_lang')}:</div>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className={styles.dropdownSelectors}>
                              <div className="languageCountry-form">
                                {countries && countries.length > 0 &&
                                  <OrthoSelectFacet className="orthoform" label={t('Country')} onChange={onChangeCountry} options={countries} cookieName="Techdocs-Temp-Country" cmpnt="regioncountrylist" load={load} setLoading={setLoading} t={t} />
                                }
                              </div>
                              <div className="languageCountry-form">
                                {languages && languages.length > 0 &&
                                  <OrthoSelectFacet className="orthoform" label={t('Language')} onChange={onChangeLanguage} options={languages} cookieName="Techdocs-Temp-Language" cmpnt="language" load={load} setLoading={setLoading} t={t} />
                                }
                              </div>
                            </Modal.Body>
                            <Modal.Footer className={styles.modalFooter}>
                              <Button className={styles.buttonSecondary} variant="secondary" onClick={() => {
                                handleClose()
                              }}>
                                {t('Cancel')}
                              </Button>
                              {((countrySelected && languageSelected) ||
                                (Cookies.get("Techdocs-Temp-Country") != null && Cookies.get("Techdocs-Temp-Language") != null)) &&
                                <Button className={styles.buttonPrimary} variant="primary" onClick={() => {
                                  let docClasses = [];
                                  if (countrySelected != null)
                                    Cookies.set("Techdocs-Country", countrySelected, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
                                  if (languageSelected != null)
                                    Cookies.set("Techdocs-Language", languageSelected, { expires: new Date(new Date().getTime() + Cookie_expiry_time) })
                                  if ((Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null)) {
                                    setDocClass([...docClasses])
                                    if (Cookies.get("Techdocs-Country") !== 'ALL')
                                      setFilter("regioncountrylist", Cookies.get("Techdocs-Country"), "any");
                                    else
                                      removeFilter("regioncountrylist");
                                    setFilter("language", [languageSelected, "Multiple Languages"], "any");
                                    setFilter("audience", [...getAudienceType(userInfo)], "any")
                                    clearFilters(["regioncountrylist", "language", "audience", "usage_type"])
                                    setResultsSelected([])
                                    console.log("docClasses lang are reset to ", docClass)
                                  }
                                  handleClose();
                                  redirectToLogin();
                                }}>{t('Apply')}</Button>}
                            </Modal.Footer>
                          </Modal> */}
                        </nav>
                        {/* <Header title={t('company_title')} /> */}

                        


                      </header>

                      {/* header form api call  */}
                      {/* {
                        //process.env.REACT_APP_InitialLanguage.split(',').includes(currenti18nLanguageCode) &&
                        
                        (showHeaderLoader  ?
                          <div className="td-headerPlaceholder">Loading...</div>
                        :
                          <div id="headerText" className="" dangerouslySetInnerHTML={{ __html: header }}></div>)
                      } */}
                      {/* <AemHeader 
                      /> */}

                      {/* fragment  */}
                      {

                        showHeaderLoader ?
                            <div className="headerPlaceholder">Loading...</div>
                            :
                            <div id="header" className="xf-web-container" dangerouslySetInnerHTML={{ __html: header }}></div>

                      }
                      
                      <header id='globalNav-Tab' className='global-header-tab' style={{ position: 'relative' }}>
                        <nav className="top-nav">
                        
                        <a href={headerFooterDomain}><img className='td-ortho-logo' src={require('./assets/OrthoLogo_WHT.svg').default}  alt="Ortho Logo" /></a>


                          <div className="container text-right">
                            <div className="language-select">
                              <div className="d-flex justify-content-begin align-items-center language-select-root float-right">
                                <div className="dropdown" style={{ marginTop: '1px' }}>
                                  <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" >
                                    <GlobeIcon /><span style={{ padding: '0.25rem 1rem' }}>{currenti18nLanguageName}</span>
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                    <li>
                                      <span className="dropdown-item-text">{t('language')}</span>
                                    </li>
                                    {i18nLanguages.map(({ code, name, country_code }) => (
                                      <li key={`${country_code}-${code}`}>
                                        <a
                                          href="#"
                                          className={classNames('dropdown-item', { disabled: currenti18nLanguageCode === code, })} style={{ color: '#212529' }}
                                          onClick={() => {
                                            i18next.changeLanguage(code)
                                            window.location.reload();
                                          }}
                                        >
                                          <span
                                            className={`flag-icon flag-icon-${country_code} mx-2`}
                                            style={{
                                              opacity: currenti18nLanguageCode === code ? 0.5 : 1,
                                            }}
                                          ></span>
                                          {name}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            {userLoggedIn &&
                              <span className="mb-0 mt-1 welcome">{t('welcome_message')} {userInfo.given_name},{userInfo.family_name}</span>
                            }
                            {(showLogin && Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null) &&
                              <a href={`${login_url}${redirect_uri}`} onClick={() => { Cookies.set("placeHolder", "placeHolder", { expires: 0.0007 }); Cookies.set("manualLogin", "true", { expires: new Date(new Date().getTime() + 1 * 60 * 1000) }); return true }} style={{ fontSize: "14px", cursor: "pointer" }}>{t('login')}</a>
                            }
                            {(showLogin && (Cookies.get("Techdocs-Country") === undefined || Cookies.get("Techdocs-Language") === undefined)) &&
                              <a onClick={() => handleShowLogin()} style={{ fontSize: "14px", cursor: "pointer" }}>{t('login')}</a>
                            }
                            <react-comment variant="primary" onClick={handleShow} style={{ color: "#fff", border: '0px' }} className="LangCountryButton">
                              {/*t('langCountry')*/}
                            </react-comment>
                            {userLoggedIn &&
                              <a href={`${logout_url}${redirect_uri}`} style={{ fontSize: "14px" }} onClick={() => { Cookies.remove("at"); Cookies.remove("rt"); Cookies.remove("placeHolder"); Cookies.remove("redirectURL"); Cookies.remove("count"); Cookies.remove("manualLogin"); return true }}>{t('logout')}</a>
                            }

                            {
                              userLoggedIn &&
                              isEmployee(userInfo) &&
                              <FaqLink />
                            }

                          </div>

                          

                          {countries && countries.length > 0 &&  !isLoading && languages && languages.length > 0 &&  <ModalDropdown setShow={setShow}  show={show} userInfo={userInfo} countrySelect={countrySelected} setCountrySelect={setCountrySelected} languageSelect={languageSelected} setLanguageSelect={setLanguageSelected} country={countries} language={languages} setFilterData={setFilter} removeFilt={removeFilter} clearFilter={clearFilters}
                            setResultSelect={setResultsSelected} />  
                         }

                                               </nav>
                        {/* <Header title={t('company_title')} /> */}

                        


                      </header>

                          {/* header for mobile */}
                      <div className="navbar-header-mobile">
                      {/* <img className='td-ortho-logo' src={require('./assets/OrthoLogo_WHT.svg').default}  alt="Ortho Logo" /> */}

                          {userLoggedIn &&
                            <p className="mb-0 mt-1 welcome" style={{ fontSize: "12px", margin:"0 10px" }}>{t('welcome_message')} {userInfo.given_name},{userInfo.family_name}</p>
                          }

                          <div className="mobile-selectors-container">
                          
                          {(showLogin && Cookies.get("Techdocs-Country") != null && Cookies.get("Techdocs-Language") != null) &&
                            <a href={`${login_url}${redirect_uri}`} onClick={() => { Cookies.set("placeHolder", "placeHolder", { expires: 0.0007 }); Cookies.set("manualLogin", "true", { expires: new Date(new Date().getTime() + 1 * 60 * 1000) }); return true }} style={{ fontSize: "14px", margin:"0 10px", cursor: "pointer" }}><MdLogin className="mobileIcon" /></a>
                          }

                          {(showLogin && (Cookies.get("Techdocs-Country") === undefined || Cookies.get("Techdocs-Language") === undefined)) &&
                              <a onClick={() => handleShowLogin()} style={{ fontSize: "14px", cursor: "pointer", margin:"0 10px" }}><MdLogin className="mobileIcon" /></a>
                          }
 
                          {
                            userLoggedIn &&
                            isEmployee(userInfo) &&
                            <FaqLink />
                          }
                          
                          <react-comment MdOutlineLanguage className="mobileIcon" onClick={handleShow} />
                          <div className="language-select">
                            <div className="d-flex justify-content-begin align-items-center language-select-root float-right">
                              <div className="dropdown" style={{marginTop:'1px'}}>
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#fff' }} >
                                  <GlobeIcon fill="#fff" width={16} height={16} />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                  <li>
                                    <span className="dropdown-item-text">{t('language')}</span>
                                  </li>
                                  {i18nLanguages.map(({ code, name, country_code }) => (
                                    <li key={`${country_code}-${code}`}>
                                      <a
                                        href="#"
                                        className={classNames('dropdown-item', { disabled: currenti18nLanguageCode === code, })} style={{ color: '#212529' }}
                                        onClick={() => {
                                          i18next.changeLanguage(code)
                                          window.location.reload();
                                        }}
                                      >
                                        <span
                                          className={`flag-icon flag-icon-${country_code} mx-2`}
                                          style={{
                                            opacity: currenti18nLanguageCode === code ? 0.5 : 1,
                                          }}
                                        ></span>
                                        {name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          {userLoggedIn &&
                            <a href={`${logout_url}${redirect_uri}`} style={{ fontSize: "14px", color:'#fff', marginLeft:"10px" }} onClick={() => { Cookies.remove("at"); Cookies.remove("rt"); Cookies.remove("placeHolder"); Cookies.remove("redirectURL"); Cookies.remove("count"); Cookies.remove("manualLogin"); return true }}><MdLogout size={'1.5em'} style={{ marginRight: '5px' }} /></a>
                          }
                      
                          </div>

                      </div>

                      {location.pathname.includes("search-result") &&<div style={{heigth: "30px", backgroundColor: '#f3f6f7'}}>
                        <div className="home-container">
                        <Link to="/tdhome" style={{borderLeft: '2px solid #ccc', borderRight: '2px solid #ccc', padding: '0 10px'}} 
                        onClick={() => {
                          console.log('i am getting this')
                          clearFilters(["regioncountrylist","language","audience","usage_type", "expirationdate"])
                          var options = { shouldClearFilters: false }
                          setSearchTerm("",options);
                          Cookies.set("search-term", "", { expires: 0.0007 });
                        } 
                        }>{t('Home')}</Link>
                        </div>
                      </div>}
                      <Routes>
                        <Route key="root" path="/" element={ <Navigate to="/tdhome" /> }/>
                        <Route key="tdhome" path="tdhome" element={
                          <div>
                            <Hero title={t('Technical_Documents')} caption={t(('Moto').toString())} placeHolderText={t('search_pre')} searchField = {searchFields} searchClick={searchClick} setShow={setShow} setSearchTerm={setSearchTerm} searchTerm={searchTerm} t={t} Cookie_expiry_time={Cookie_expiry_time}
                            fullText={fullText} handleFullText={handleFullText} filters={filters}/*docClass={docClass}*/
                            expirySearch={expirySearch} handleExpiredDocs={handleExpiredDocs} removeFilter={removeFilter} setFilter={setFilter}  userLoggedIn={userLoggedIn} isEmployee={isEmployee} userInfo={userInfo}
                            />
                          </div>
                        } />                        
                        <Route path="/Modal" element={<ModalDropdown to="/ModalDropdown" />} />
                        <Route key="search-result" path="/search-result" element={<Navigate to="/tdhome/search-result"/> } />
                        <Route key="tdhome-search-result" path="tdhome/search-result" element={
                          <>
                            <section className="tech-docs" id="search-result" >
                              <div className="td-container">
                                {wasSearched &&
                                  <div className='filter'>
                                    <div style={{ display: 'grid', gridTemplateColumns: '4fr 4fr' }}>
                                      <div className="filter-head-cnt">
                                        <h3 className={searchStyles.label}>
                                          <span>{t('Filter')}:</span></h3>
                                      </div>
                                      <div>
                                        {isClearFilter && isDistributor && <span><ClearFilters filters={filters} resetDocClasses={resetDocClasses} clearFilters={clearFilters} title={t('Clear Filters')}  setIsClearFilter={setIsClearFilter} /></span>}
                                        {isClearFilter && !isDistributor && <span><ClearFilters filters={filters} resetDocClasses={resetDocClasses} clearFilters={clearFilters} title={t('Clear Filters')} setIsClearFilter={setIsClearFilter} /></span>}
                                      </div>
                                    </div>

                                    <div className="filter-options">

                                      <div className="card-header" style={{ fontSize: '12px', padding: '.75rem' }}>
                                        {t('Country')}:
                                        <span style={{ fontSize: '12px'}} className={searchStyles.country}>{t(Cookies.get("Techdocs-Country"))} </span>
                                        <span style={{ padding: '0 5px' }}>|</span>
                                        {t('Language')}:
                                        <span style={{ fontSize: '12px'}} className={searchStyles.country}>{t(Cookies.get("Techdocs-Language"))}</span>
                                        <FaEdit className="indigo" style={{ float: 'right', fontSize: '18px' }} title={t('Edit')} onClick={handleShow} />
                                      </div>
                                     
                                      <SearchInput placeHolderText={t('search_pre')} searchClick={searchClick} setShow={setShow} searchField={searchFields} searchFieldsetSearchTerm={setSearchTerm} searchTerm={searchTerm} t={t}></SearchInput>
                                      <label><input type="checkbox" value={fullText} style={{marginRight: '10px'}} onChange={() => handleFullText(!fullText)} checked={fullText}/>
                                      {t('Full Text')}</label>
                                      <div>
                                        {
                                          userLoggedIn &&
                                          isEmployee(userInfo) &&
                                          <label>
                                            <input type="checkbox" checked={!(filters?.some(obj => obj?.field === "expirationdate" )) || expirySearch} style={{margin: '0 10px 0 0'}} onChange={(e)=>handleExpiredDocs(e, removeFilter, setFilter)}/>
                                            {t("Include Expired Content")}
                                          </label>
                                        }
                                      </div>
                                      <p id="show-filter" onClick={() => toggleMenu(true)} className="show-mobile" style={{ display: 'none' }}>{t('Expand filters')}</p>
                                      <div id="test" className="mobile-hidden">

                                        <Facet id="business_input" field="business_line" optionsMap={altDispValues} label={t('Business_Line')} view={TechdocsFilterRadio} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.business_line, "business_line")} show={100}/>
                                        <Facet style={{ borderBottom: '5px solid #F3F6F7', padding: '30px 0' }} mapContextToProps={context => sortFacetValues(context, context.facets.document_classification, "document_classification")} field="document_classification" optionsMap={altDispValues} /*docClass={docClass}*/ optionClickAdd={optionClickAdd} optionClickRemove={optionClickRemove}
                                          label={t('Doc_Categories')} 
                                          filterType="any" t={t} show={100}
                                          view={(props)=><TechdocsFilter filterProps={props} removeFilter={removeFilter} />} 
                                          />
                                        {isClearFilter && !isDistributor && Cookies.get("Techdocs-Country") === 'ALL' && <>
                                          <Facet field="document_classification_subcategory" optionsMap={altDispValues} label={t('Doc_Sub_Categories')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.document_classification_subcategory, "document_classification_subcategory")} show={100}/>
                                          <Facet field="reagentlist" optionsMap={altDispValues} label={t('Reagent_Type')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.reagentlist, "reagentlist")} show={100}/>
                                          <Facet field="equipmentlist" show={100} optionsMap={altDispValues} label={t('Equipment')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.equipmentlist, "equipmentlist")} /></>}
                                        {isClearFilter && !isDistributor && Cookies.get("Techdocs-Country") !== 'ALL' && <>
                                          <Facet field="document_classification_subcategory" optionsMap={altDispValues} label={t('Doc_Sub_Categories')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.document_classification_subcategory, "document_classification_subcategory")} show={100} />
                                          <Facet field="reagentlist" optionsMap={altDispValues} label={t('Reagent_Type')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.reagentlist, "reagentlist")} show={100}/>
                                          <Facet field="equipmentlist" show={100} optionsMap={altDispValues} label={t('Equipment')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.equipmentlist, "equipmentlist")} /></>}
                                        {isClearFilter && isDistributor && <>
                                          <Facet field="document_classification_subcategory" optionsMap={altDispValues} label={t('Doc_Sub_Categories')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.document_classification_subcategory, "document_classification_subcategory")}  show={100}/>
                                          <Facet field="reagentlist" optionsMap={altDispValues} label={t('Reagent_Type')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.reagentlist, "reagentlist")} show={100}/>
                                          <Facet field="equipmentlist" show={100} optionsMap={altDispValues} label={t('Equipment')} view={TechDocsFilterI18n} filterType="any" t={t} mapContextToProps={context => sortFacetValues(context, context.facets.equipmentlist, "equipmentlist")} /></>}
                                      </div>
                                      <p id="collapse-filter" className="show-menu" style={{ display: 'none' }} onClick={() => toggleMenu(false)}>{t('Collapse filters')}</p>

                                    </div>
                                  </div>}
                                <div className="tech-docs--documents">
                                  <div className="tech-docs--documents-header">
                                    <div className="tech-docs--results">
                                      {wasSearched && <><PagingInfo
                                        mapContextToProps={context => {
                                          if (!context.facets.document_classification) return context;
                                          return {
                                            ...context,
                                            totalResults: getTotalResultsCount(context.facets.document_classification, context.filters, context.totalResults, isDistributor),
                                          };
                                        }}
                                        view={({ start, end, totalResults, searchTerm }) => 
                                          {
                                        return(
                                          <div className="paging-info">
                                            {totalResults === 0 &&  
                                            location.search.includes("isauthenticated=authenticated") &&
                                            (userLoggedIn ? 
                                                <p className="no-results">{t('No Results found')} </p> :
                                                <p className="no-results">{t('No Results Authenticated')} </p>
                                            )
                                            }
                                            
                                            {totalResults === 0 &&  
                                            location.search.includes("isauthenticated=internal") &&                                             
                                            (userLoggedIn ? 
                                              (isEmployee(userInfo) ? 
                                                <p className="no-results">{t('No Results found')} </p> :
                                                <p className="no-results">{t('No Results Internal')} </p>)
                                              :
                                              <p className="no-results">{t('No Results Internal')} </p>)
                                            }

                                            {totalResults === 0 && (!location.search.includes("isauthenticated")) && <p className="no-results">{t('No Results found')} </p>}
                                            {totalResults > 0 && <span>{t('Showing')} <strong>{start}</strong> - <strong>{end}</strong> {t('out_of')} <strong>
                                              {totalResults} </strong>{resultSearchTerm && resultSearchTerm.length > 0 && <span>for:</span>}<i>{resultSearchTerm}</i>
                                            </span>}

                                          </div>
                                          )}}

                                      />
                                        {(results.length > 0) && <div className="resultsperPage">
                                          <ResultsPerPageFilter options={page_size_options} ResultsPerPage={resultsPerPageSize} setResultsPerPage={setResultsPerPage} setResultsPerPageSize={setResultsPerPageSize} Cookie_expiry_time={Cookie_expiry_time} t={t}/>
                                        </div>}
                                        {resultsSelected.length > 0 && <div style={{ marginTop: '10px' }} >
                                          <Button variant="primary" className="document indigobg" style={{ border: "0px", color: "#fff" }} onClick={event => { setLoad(!load); downloadSelected(event, t('downloadAll_fileName')) }} >{t('Download_Selected')} ({resultsSelected.length} )</Button>
                                          <FaTimes onClick={() => setResultsSelected([])} title={t('Clear Selected')} />
                                        </div>}
                                      </>}
                                    </div>
                                    {(wasSearched && (results.length > 0)) && <div className="tech-docs--sort" style={{ alignItems: 'inherit' }}>
                                      <Sorting label={t('Sort by')} className="orthoform"
                                        sortOptions={[
                                          { name: t("Doc_Sub_Categories"), value: "document_classification_subcategory", direction: "asc" },
                                          { name: t("Published_Date"), value: "publisheddate", direction: "desc" },
                                          { name: t("Relevance"), value: "", direction: "asc" },
                                          { name: t("Title"), value: "title", direction: "asc" }
                                        ]}
                                        
                                      />
                                    </div>}


                                  </div>

                                  {results.length > 0 && <div style={{ display: "grid", gridTemplateColumns: '3fr 1fr', marginTop: '28px' }}>
                                    <SelectAll getURL={getURL} resultsSelected={resultsSelected} results={results} handleOnResultChange={handleOnResultChange} setResultsSelected={setResultsSelected} t={t}></SelectAll>
                                    <div className='toggle-icon-text' >

                                      <span style={{paddingRight:"13px"}} onClick={() => { 
                                      Cookies.set('showCollapse',!showCollapse,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
                                      setShowCollapse(!showCollapse);
                                       }}> {showCollapse ? t("Collapse") : t('Expand')} </span>
                                    </div> </div>}
                                    {results.length > 0  &&  isEmployee(userInfo) && <div className='toggle-icon-text' style={{ display: "grid", gridTemplateColumns: '3fr 1fr', textAlign: 'left' , marginLeft:"5px"}}>
                                        <span onClick={() => { 
                                        Cookies.set('showToggle',!showToggle,{ expires: new Date(new Date().getTime() + Cookie_expiry_time) });
                                        setShowToggle(!showToggle);
                                        }}> {showToggle ? t('Hide Supported-Compound Documents') : t('Show Supported-Compound Documents')} </span>
                                      </div>}
                                    <div className="top-orthoPaging-cnt">
                                   
                                    <div >
                                      <Paging className="top-orthoPaging" />
                                    </div>

                                    </div>

                                  <div className="tech-docs--documents-download" style={{ marginTop: '20px' }}>
                                    <form>
                                      <Results titleField="title"
                                        clickThroughTags={["Dashboard"]}
                                        urlField="nps_link"
                                        thumbnailField="image_url"
                                        shouldTrackClickThrough={true}
/*                                        mapContextToProps={context => {
                                          console.log("context results is " + context.results)
                                          if (!context.results || (context.results && context.results.length === 0)) return context;
                                          return {
                                            ...context,
                                            results: getUpdatedResults(context.results)
                                          };
                                        }}*/
                                        mapContextToProps={context => {
                                          if (!context.results) return context;
                                          if (context.sortField === "document_classification_subcategory"){
                                            let grouped = context.results.reduce((result, obj) => {
                                              if (result[obj.document_classification_subcategory.raw]) {
                                                result[obj.document_classification_subcategory.raw].push(obj) 
                                                  result[obj.document_classification_subcategory.raw]=result[obj.document_classification_subcategory.raw].sort((m,n)=>{return new Date(n.publisheddate.raw) - new Date(m.publisheddate.raw)})
                                              } else {
                                                result[obj.document_classification_subcategory.raw] = [obj]
                                              }
                                              return result
                                            }, {})

                                            

                                            console.log("grouped",[].concat.apply([], Object.values(grouped)));
                                            

                                            return {
                                              ...context,
                                              results: [].concat.apply([], Object.values(grouped))
                                            };
                                          }else{
                                            return context
                                          }

                                        }}
                                        view={props => Documents({
                                          ...props, /*initResults: setInitialResultSet(results)*/
                                        })}
                                        resultView={props => {
                                          return <DocumentResult
                                          compoundToggle = {showToggle}
                                          showCollapse= {showCollapse}
                                          isEmployee= {isEmployeeOrDistributor(userInfo)}
                                          isUserAuthenticated= {userLoggedIn}
                                          resultsSelected={resultsSelected}
                                          results={results}
                                          handleOnResultChange={handleOnResultChange}
                                          access_Token={access_Token}
                                          altDispValues= {altDispValues}
                                          trackClickThrough= {trackClickThrough}
                                          t= {t}
                                          load= {load}
                                          setLoading= {setLoading}
                                          {...props}/>
                                        }
                                      }
                                        />
                                    </form>
                                  </div>
                                  <Paging className="orthoPaging" 
                                    view = {(props)=>{
                                      return <Paging className="orthoPaging" 
                                        onChange={(...args)=>handlePageChange(props.onChange, ...args)}
                                      />
                                    }}
                                  />
                                </div>
                              </div>
                            </section>
                          </>
                        }
                        />
                      </Routes>
                      {/* <Footer t={t} /> */}

                      {
                        //process.env.REACT_APP_InitialLanguage.split(',').includes(currenti18nLanguageCode) ?

                        (showFooterLoader  ?
                        <div className="td-footerPlaceholder">Loading...</div>
                        :
                        <div id="footerText" className="xf-web-container" style={{ marginTop: "70px" }} dangerouslySetInnerHTML={{ __html: footer }}></div>
                        )
                        /*:
                        <Footer t={t} /> */
                      }
                    </div>
                  )}

                </ErrorBoundary>
              )
            }
            }
          </WithSearch>
        </ModalProvider>
      </SearchProvider>
    </AppRoot>
  );
}
export default App;
